<template>
	<modal-wrapper @close="saveAndClose">
		<div id="releasenotes" class="modal-dialog">
			<dlg-header title="Release Notes" @close="saveAndClose">
				<svg-icon icon="star" />
			</dlg-header>
			<div class="modal-body scroll">
				<template v-for="release in releases">
					<div :key="release.version" class="release">
						<strong>{{ release.title }}</strong>
						<div v-html="release.html"></div>
					</div>
				</template>
				<div v-if="releases.length === 0">
					Nothing new to report!
				</div>
			</div>
			<div class="footer right">
				<button v-if="hasMore" class="btn btn-default" @click="showMore()">Show More</button>&nbsp;
				<button class="btn btn-primary" @click="saveAndClose()">Close</button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import axios from 'axios'

import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { compareVersions } from '@utils/versionCompare.js'

import releases from '@assets/versions.json'
import { userData } from '@services/userData'

const ReleaseNotes = {
	name: 'ReleaseNotes',
	components: {
		DlgHeader,
		ModalWrapper,
	},
	props: {
		hasMore: {
			type: Boolean,
			require: true,
		},
		releases: {
			type: Array,
			required: true,
		},
	},
	methods: {
		showMore() {
			let route = this.$router.resolve({ name: 'release-notes' })
			window.open(route.href, '_blank')
		},
		saveAndClose() {
			// TODO: API SAVE
			if (this.lastVersion !== 'ALL') this.$store.dispatch('setLastViewedVersion')
			dlg.close(this)
		},
	},
}

function compareDataVersions(a, b) {
	return compareVersions(a.version, b.version)
}

function comparedPinned (a, b) {
	if (a.pinned && !b.pinned) return -1
	else if (!a.pinned && b.pinned) return 1
	else return 0
}

export default ReleaseNotes

/**
 * Load Releases that match the community type and are newer than the last provided version.
 * 'ALL' will show all recorded version notes, and 'LATEST' will only provide the most recent one.
 * @param {String} lastVersion
 * @param {Boolean} isCommunity
 */
export async function openReleaseNotes(lastVersion = 'LATEST', hideWhenEmpty = false) {
	let releases = await getReleaseNotes(lastVersion, hideWhenEmpty)
	if (!releases) return
	let hasMore = releases.length > 3
	releases = releases.slice(0, 3)

	return dlg.open(
		ReleaseNotes,
		{
			releases,
			hasMore,
		},
		false
	)
}

const DESKTOP = 'desktop'
const COMMUNITY = 'community'
const OMNI = 'template'

export async function getReleaseNotes(lastVersion = 'LATEST', hideWhenEmpty = false) {
	const isDesktop = !!window.ElectronInterface
	const isCommunity = userData.claims.isCommunityUser

	const template = isDesktop ? DESKTOP : isCommunity ? COMMUNITY : OMNI
	let sorted = releases
		.filter(release => {
			if (release[template] && release[template].length) return true
			if (isDesktop && release[OMNI] && release[OMNI].length) return true
			return false
		})
		.sort(compareDataVersions)
		.reverse()

	// If we want to "pin" a Release to the top of the list, we can add a "pinned" property to the release object at the versions.json file.
	sorted.sort(comparedPinned) // Pinned releases first
	if (lastVersion === 'LATEST') sorted = sorted.slice(0, 1)
	else if (lastVersion.includes('.'))
		sorted = sorted.filter(release => compareVersions(release.version, lastVersion) > 0)

	// Prevent auto showing list on release updates if nothing to show (usually hotfixes, or after Thick client releases)
	if (!sorted.length && hideWhenEmpty) return

	const promises = []
	sorted.forEach(release => {
		const file = release[template] || (isDesktop && release[OMNI])
		// fetch HTML file.
		if (file)
			promises.push(
				axios.get('/release-notes/' + file).then(({ data }) => {
					release.html = data
				})
			)
	})
	// Load info before showing the modal
	await Promise.all(promises)
	return sorted
}
</script>

<style lang="scss" scoped>
.release {
	&:not(:last-child) {
		padding-bottom: 1rem;
		margin-bottom: 12px;
		border-bottom: 1px solid var(--primary-border);
	}
}
</style>

<style lang="scss">
@import '~@styles/_vars.scss';

#releasenotes {
	@media (min-width: $mqMedium) {
		min-width: 475px;
	}
}
</style>

import store from '@store'
import windows from '@store/modules/windows'

let w
const initializeInactivityTimeout = () => {
	if (!window.config.enableInactivityTimeout) return
	console.log('Inactivity Timeout feature enabled')
	const isSharedWorkerSupported = () => {
		let supports = false
		const tester = {
			get type() {
				supports = true
			},
		}
		try {
			supports = !!new SharedWorker('blob://', tester)
		} finally {
			// eslint-disable-next-line no-unsafe-finally
			return supports
		}
	}
	if (!window.config.enableInactivityTimeout || !isSharedWorkerSupported()) return
	w = new SharedWorker('timerWebWorker.js', 'asteris-inactivity-logout-worker').port
	w.start()
	w.onmessage = function(event) {
		if (event.data === 'logout') {
			stopInactivityTimeout()
			window.localStorage.setItem('inactivityTimeout', true)
			// force to close all windows before logging out
			store.commit('CLOSE_ALL_STUDY_WINDOWS', windows.state.openedStudyWindowReferences)
			store.dispatch('logOut')
		}
	}

	window.onblur = resetTimer
	window.onfocus = resetTimer
	window.onclick = resetTimer
	document.addEventListener('mousemove', resetTimer, false)
	document.addEventListener('mousedown', resetTimer, false)
	document.addEventListener('keypress', resetTimer, false)
	document.addEventListener('touchmove', resetTimer, false)
	document.addEventListener('onscroll', resetTimer, false)
	startTimer()
}

function startTimer() {
	const msg = {
		type: 'enableTimeout',
		timer: parseInt(window.config.inactivityTimeout),
	}
	w.postMessage(msg)
}

function resetTimer() {
	const msg = {
		type: 'resetTimeout',
		timer: parseInt(window.config.inactivityTimeout),
	}
	w.postMessage(msg)
	startTimer()
}
const stopInactivityTimeout = () => {
	const msg = {
		type: 'disableTimeout',
		timer: parseInt(window.config.inactivityTimeout),
	}
	w.postMessage(msg)
	window.onblur = null
	window.onfocus = null
	window.onclick = null
	document.removeEventListener('mousemove', resetTimer, false)
	document.removeEventListener('mousedown', resetTimer, false)
	document.removeEventListener('keypress', resetTimer, false)
	document.removeEventListener('touchmove', resetTimer, false)
	document.removeEventListener('onscroll', resetTimer, false)
	w.close()
}

export { initializeInactivityTimeout, stopInactivityTimeout }

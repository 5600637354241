<template>
	<div class="TeleconsultationThumbnails">
		<p v-if="!readOnly">
			<svg-icon icon="info-circle" class="is-info" fixed />
			<!-- eslint-disable-next-line vue/no-v-html -->
			<span v-html="instruction"></span>
		</p>
		<ol v-if="thumbnails.length">
			<li
				v-for="(thumbnail, i) in thumbnails"
				:key="i"
				draggable
				@dragstart="onDragStart(thumbnail, $event)"
				@click.prevent="readOnly ? openViewer(thumbnail) : toggleSeries(thumbnail.seriesId)"
			>
				<div
					class="thumbnail"
					alt="Thumbnail preview of first image in series"
					:style="{
						'background-image': `url(${findThumbnailUrl(thumbnail)})`,
					}"
				>
					<div v-if="seriesToExclude.includes(thumbnail.seriesId)" class="excluded">
						Excluded
					</div>
				</div>
				<div class="thumbnail-label">
					<strong v-if="thumbnail.seriesNumber">{{ thumbnail.seriesNumber }}</strong>
					<span v-if="thumbnail.simpleName" class="thumbnail-name">{{ thumbnail.simpleName }}</span>
					<p v-if="thumbnail.numberOfImages > 1" class="thumbnail-images"> ({{ thumbnail.numberOfImages }} Images) </p>
				</div>
			</li>
		</ol>
		<attachment-list
			class="attachment-list"
			:attachments="attachments"
			:open-on-click="readOnly"
			:series-to-exclude="seriesToExclude"
			@select="toggleSeries"
		/>
		<div v-if="showAddStudyButton && !readOnly" style="margin-top: 20px;">
			<button class="btn btn-default" @click="$emit('add-study')">
				Add Study
			</button>
			&nbsp;
			<button class="btn btn-default" @click="$emit('add-files')">
				Add File
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { findThumbnailUrl } from '@utils/urlUtility'
import AttachmentList from '@components/AttachmentList.vue'
import GoogleAnalytics from '@services/analyticsService'

export default {
	name: 'TeleconsultationThumbnails',
	components: {
		AttachmentList,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		attachments: {
			type: Array,
			default: () => [],
		},
		thumbnails: {
			type: Array,
			default: () => [],
		},
		reportId: {
			type: String,
			default: null,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		showAddStudyButton: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		seriesToExclude: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		instruction() {
			let instruction = 'Click on an '
			if (this.thumbnails.length) instruction += 'image '
			if (this.thumbnails.length && this.attachments.length) instruction += 'or '
			if (this.attachments.length) instruction += 'attachment'
			instruction += ' to <strong>exclude</strong> it from submission.'
			return instruction
		},
	},
	methods: {
		...mapActions(['openStudy']),
		findThumbnailUrl,
		toggleSeries(seriesId) {
			if (this.readOnly) return
			const seriesToExcludeIndex = this.seriesToExclude.indexOf(seriesId)
			if (seriesToExcludeIndex >= 0) {
				this.seriesToExclude.splice(seriesToExcludeIndex, 1)
			} else {
				this.seriesToExclude.push(seriesId)
			}
		},
		openViewer(thumbnail) {
			if (this.reportId === '00000000-0000-0000-0000-000000000000') return
			try {
				GoogleAnalytics.sendGAEvent('Teleconsultation', {
					event_category: 'Launch',
					event_label: 'Teleconsultation Viewer'
				})
			}
			catch (err) {
				console.log(err)
			}
			this.openStudy({
				reportId: this.reportId,
				initialSeriesId: thumbnail.seriesId,
			})
		},
		onDragStart(thumbnail, event) {
			const data = JSON.stringify({ seriesId: thumbnail.seriesId, reportId: this.reportId })
			event.dataTransfer.setData('text/plain', data)
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.TeleconsultationThumbnails {
	p {
		font-weight: 400;
		padding: 0 0.5em 0.5em 0;
	}

	.thumbnail {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		height: 125px;
		background-size: contain;
		background-color: black;
		background-position: center;
		background-repeat: no-repeat;
		user-select: none;

		&:hover {
			opacity: 0.8;
		}

		.excluded {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.9;
			font-weight: 500;
			font-style: italic;
			background: var(--primary-bg);
		}
	}

	.thumbnail-label,
	strong,
	.thumbnail-images {
		font-size: 0.9em;
		text-align: center;
		word-break: break-word;
		hyphens: auto;
	}

	strong+.thumbnail-name::before {
		content: ': ';
	}

	ol+.attachment-list {
		margin-top: 20px;
	}

	ol {
		list-style: none;

		li {
			cursor: pointer;
			list-style: none;
			display: inline-block;
			width: 125px;
			margin: 10px;
			vertical-align: top;
		}
	}
}
</style>

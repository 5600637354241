import { ReportDetail } from '@reporting/classes'
import api, { API, paramsSerializer, RESTAPI } from '@services/api'
import { clinicAPI } from '@services/clinicAPI'
import { addNotification } from '@services/notificationService'
import store from '@store'
import reportService from './reportService'
import { uploadData } from './uploads'
import { userData } from './userData'
import workflow from './workflow'

const VETCTAPI = 'VETCTAPIINTEGRATION'

class ConsultationService {
	async startRequestReport(studyId: string, consultantId: string): Promise<ReportDetail> {
		workflow.consultantId = consultantId
		const studyIds = studyId.split(',')
		const report = await this.start(consultantId, studyIds)
		return report
	}

	async submitImageOnlyRequest(request: IImageOnlyRequest): Promise<boolean> {
		workflow.isLoading = true
		try {
			await API.post(`/reporting/submit-image-only-request`, request)
			const studyCount = request.studyIds.length
			let message = `Successfully submitted `
			if (studyCount > 0) message += `the ${studyCount > 1 ? 'studies' : 'study'} `
			message += `to ${workflow.consultant.name}!`
			addNotification(message, 'success')
			uploadData.stopUploads({ context: 'teleconsultation-request' })
			return true
		} finally {
			workflow.isLoading = false
		}
	}

	async createCase(request: INewConsultationRequest) {
		return RESTAPI.post(`/radiology/create-case`, request)
	}

	async getReportSLA(provider: string, clinicCode: string, caseKey: string) {
		return RESTAPI.get(`/sla/get-report-sla?Provider=${provider}&ClinicCode=${clinicCode}&CaseKey=${caseKey}`).then(
			r => r.data
		)
	}

	async updateReportSLA(slaData: ISlaUpdateData) {
		return RESTAPI.put('/sla/update', slaData)
	}

	async submitTeleconsultationRequest(request: INewConsultationRequest): Promise<boolean> {
		workflow.isLoading = true
		try {
			let consultant = await this.getConsultantInfo(request.consultantId)
			if (consultant.clinicCode === VETCTAPI) {
				await RESTAPI.post(`/radiology/submit-request`, request)
			} else {
				await API.post(`/reporting/submit-request`, request)
			}
			const studyCount = request.studyIds.length
			let message = `Successfully submitted `
			if (studyCount > 0) message += `the ${studyCount > 1 ? 'studies' : 'study'} `
			message += `to ${workflow.consultant.name}!`
			addNotification(message, 'success')
			uploadData.stopUploads({ context: 'teleconsultation-request' })
			return true
		} finally {
			workflow.isLoading = false
		}
	}

	async submitCase(provider: string, clinicCode: string, caseKey: string) {
		return RESTAPI.put(`/radiology/submit-case?Provider=${provider}&ClinicCode=${clinicCode}&CaseKey=${caseKey}`).then(
			r => r.data
		)
	}

	async getCase(provider: string, consultantReportId: string) {
		return RESTAPI.get(`/radiology/get-case?Provider=${provider}&ConsultantReportId=${consultantReportId}`).then(
			r => r.data
		)
	}

	async downloadExternalReport(providerClinicCode: string, clientClinicCode: string, consultantReportId: string) {
		return RESTAPI.get(
			`/radiology/download-external-report?providerClinicCode=${providerClinicCode}&clientClinicCode=${clientClinicCode}&consultantReportId=${consultantReportId}`
		).then(r => r.data)
	}

	getConsultantGroupMembers(consultantId: string): Promise<any[]> {
		return API.get(`teleconsultation/group-members/${consultantId}`).then(r => r.data)
	}
	getConsultantRegions(consultantId: string) {
		return API.get(`teleconsultation/regions/${consultantId}`).then(r => r.data)
	}
	getConsultantClientClinics(consultantId: string) {
		return API.get(`teleconsultation/client-clinics/${consultantId}`).then(r => r.data)
	}
	getConsultantCommunityUsers(consultantId: string) {
		return API.get(`teleconsultation/community-users/${consultantId}`).then(r => r.data)
	}
	getConsultantRequestTypes(consultantId: string) {
		return API.get(`teleconsultation/request-types/${consultantId}`).then(r => r.data)
	}

	escalatePriority(id: string, reason: string) {
		return API.post(`/teleconsultation/${id}/escalate-priority`, { reason })
	}
	recallPetRaysReport(id: string) {
		return API.get(`/teleconsultation/recall-petrays/${id}`).then(r => r.data)
	}

	async recallReport(consultation: IConsultationForRecall) {
		return RESTAPI.get(
			`/recall/claim?ConsultantReportId=${consultation.consultantReportId}&UserId=${consultation.userId}&ClinicCode=${
				consultation.clinicCode
			}`
		).then(r => r.data)
	}

	getFolderCounts(folders) {
		// @ts-ignore
		return API.post(`/teleconsultation/folder-counts`, folders, { cancelPending: true }).then(r => r && r.data)
	}

	getList({ params, cancelToken }): Promise<IListResult<ITeleconsultation>> {
		return API.get(`/teleconsultation/list`, {
			// @ts-ignore
			cancelPending: true,
			params,
			paramsSerializer,
		}).then(r => r && r.data)
	}

	getImageOnlyReport(id: string): Promise<IImageOnlyReport> {
		return API.get(`/reporting/image-only-report/${id}`).then(r => r.data)
	}

	async getConsultantReport(id: string): Promise<ReportDetail> {
		let report: IConsultantReport = await API.get(
			`/reporting/consultant-report?id=${id}&importMachineName=${clinicAPI.importMachineName}`
		).then(r => r.data)

		await this.setReportMeta(report)
		let result = ReportDetail.loadFromConsultantReport(report)

		// if the report is complete or the consultant is viewing the report show the response
		// otherwise show the request
		let isConsultant =
			store.state.auth.claims.userId === result.consultant.id || store.state.auth.claims.isConsultantMember

		let activeTemplate = result.response
		if (!result.canViewResponse) {
			result.response.values = {}
			result.imageComments = []
		}

		if (!report.isComplete) {
			let residentResponse =
				report.residentResponses &&
				report.residentResponses.find(r => r.consultantId === userData.claims.userId && r.id)
			if (residentResponse) {
				result.applyResidentResponse(residentResponse)
			}
		}

		result.setActiveTemplate(activeTemplate)
		result.setCanRespond()

		// if the report was sent make the layout read only
		if (result.consultantReportId) {
			result.request.root.readOnly = true
		}
		result.studies = await api.viewer.getStudy({ ids: result.studyIds }, false).then(r => r.studies)
		return result
	}

	async setReportMeta(report: IConsultantReport) {
		let meta = await reportService.getTemplateMeta(report.request.template.metaVersion)
		report.request.template.meta = meta
		if (report.response) {
			report.response.template.meta = meta
		}
	}

	getRelated(id) {
		return API.get(`/teleconsultation/related-reports?id=${id}`)
	}

	getConsultants() {
		return API.get(`/teleconsultation/consultant-list`).then(r => r.data)
	}

	getOnBehalfOf(params) {
		return API.get(`/teleconsultation/on-behalf-of-list`, {
			// @ts-ignore
			cancelPending: true,
			params,
			paramsSerializer,
		})
	}

	getEmail(params) {
		return API.get(`/teleconsultation/email-list`, {
			// @ts-ignore
			cancelPending: true,
			params,
			paramsSerializer,
		})
	}

	async start(consultantId: string, studyIds: string[]): Promise<ReportDetail> {
		let report: IConsultantReport = await API.get(`/reporting/start-consultant-report`, {
			params: {
				consultantId,
				studyIds,
			},
			paramsSerializer,
		}).then(r => r.data)
		await this.setReportMeta(report)

		let meta = await reportService.getTemplateMeta(report.request.template.metaVersion)

		let result = ReportDetail.loadFromConsultantReport(report)
		result.setActiveTemplate(result.request)
		return result
	}

	complete({ id, data }) {
		return API.post(`/teleconsultation/${id}/complete`, data)
	}

	save({ id, data }) {
		return API.post(`/teleconsultation/${id}/save`, data)
	}

	setResponseTemplate({ id, data }) {
		return API.post(`/teleconsultation/${id}/set-response-template`, data)
	}

	addReportMessage(data: IReportMessageForm) {
		return API.post(`/reporting/add-report-message`, data)
	}

	shareByEmail(data) {
		return API.post(`/teleconsultation/share-by-email`, data)
	}

	updateConsultantVisibility(params) {
		return API.get(`/teleconsultation/update-consultant-visibility`, { params, paramsSerializer })
	}

	saveConsultantResponse(report: IConsultantResponseForm): Promise<any> {
		return API.post(`/reporting/save-consultant-response`, report)
	}

	deleteReport(consultantReportId: string, reason: string) {
		return API.post(`/teleconsultation/delete-consultant-report/${consultantReportId}`, { reason })
	}

	getConsultantsForAdmin(): Promise<IConsultantInfo[]> {
		return API.get('/reporting/consultants-for-admin').then(r => r.data)
	}

	getConsultantGroups(consultantId: string): Promise<IConsultantInfo[]> {
		return API.get(`/reporting/consultant-groups/${consultantId}`).then(r => r.data)
	}

	getConsultantInfo(consultantId: string): Promise<IConsultantInfo> {
		return API.get(`/reporting/consultant-info/${consultantId}`).then(r => r.data)
	}
}

export const consultations = new ConsultationService()
export default consultations
// @ts-ignore
window.consultations = consultations
